<template>
	<v-main
		class="ma-4"
	>
		<v-card
			class="pa-2"
			style="overflow: hidden;justify-content: space-around;display:flex;"
			elevation=0
		>
			<v-text-field
				prepend-icon="mdi-magnify"
				class="mt-0 pt-0"
				hide-details
				@focus="goSearch"
			></v-text-field>
			
			<v-spacer
				style="width: 5%;"
			></v-spacer>
			
			<v-chip
				v-if="islikes"
				class="pr-5"
				color="rgba(0, 0, 0, 0.1)"
				@click="like"
			>
				<v-icon
					class="mr-1"
				>mdi-thumb-up-outline</v-icon>
				{{likes}} 已赞
			</v-chip>
			
			<v-chip
				v-else
				class="pr-5"
				color="rgba(0, 0, 0, 0.1)"
				@click="like"
			>
				<v-icon
					class="mr-1"
				>mdi-thumb-up-outline</v-icon>
				{{likes}} 赞
			</v-chip>
			
			<v-spacer
				style="width: 5%;"
			></v-spacer>

			<v-btn
				icon
				@click="share"
            >
				<v-icon>mdi-share</v-icon>
			</v-btn>
		</v-card>
		
		<v-carousel
			:cycle="true"
			:show-arrows="false"
			hide-delimiter-background
			delimiter-icon="mdi-minus"
			:height="carouselheight"
			class="rounded mt-4"
		>
			<v-carousel-item
				v-for="(ad, ai) in ads"
				:key="'ad'+ai"
			>
				<v-img
					:src="ad.image"
					@click="goGoods(ad.categoryhash)"
				></v-img>
			</v-carousel-item>
		</v-carousel>
		
		<v-card
			class="mt-4 bc"
			tile
			elevation="0"
			style="display: flex;"
		>
			<v-chip
				color="orange"
				text-color="white"
				class="pr-5"
			>
				<v-avatar
					left
					class="mr-0"
				>
					<v-icon>mdi-bullhorn-outline</v-icon>
				</v-avatar>
				公告
			</v-chip>
			
			<v-carousel
				:cycle="true"
				:show-arrows="false"
				hide-delimiters
				interval=8000
				vertical
				height="32"
				class="rounded float-left ml-2 text-body-2"
				style="line-height: 32px;"
			>
				<v-carousel-item
					v-for="(notice, ni) in notices"
					:key="'notice'+ni"
				>
					{{notice}}
				</v-carousel-item>
			</v-carousel>
		</v-card>
		
		<template v-for="(category, catei) in categories">
			<v-card
				class="mt-4 pt-1 pb-1"
				:key="'category'+catei"
				elevation=0
			>			
				<v-card-title
					class="text-body-1"
				>
					<v-avatar
						size="24"
						rounded
						class="mr-2"
					>
						<v-img :src="category.image"></v-img>
					</v-avatar>
					
					{{category.name}}
				</v-card-title>
				
				<v-container>
					<v-row>
						<v-col
							class="pa-0"
							v-for="(cate, ci) in category.child"
							:key="'cate'+ci"
							cols="3"
							@click.stop="goGoods(cate.hash)"
						>
							<v-card
								tile
								elevation="0"
								class="text-center"
							>
								<v-avatar
									size="60"
									rounded
								>
									<v-img :src="cate.image"></v-img>
								</v-avatar>
								
								<v-card-subtitle
									class="text-body-2"
								>{{cate.name}}</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</template>

		<div
			style="display: flex;justify-content: center;"
		>
			<v-breadcrumbs
				:items="dealsteps"
				class="pl-3"
			>
				<template v-slot:divider>
					<v-icon>mdi-forward</v-icon>
				</template>
			</v-breadcrumbs>
		</div>
  
		<v-container
			class="pa-0"
		>
			<v-row
				no-gutters
			>
				<v-col
					class="mr-4"
				>
					<v-card
						elevation="0"
						style="display: flex;justify-content: center;"
					>
						<v-icon
							size="40"
							color="red"
						>
							mdi-bullseye-arrow
						</v-icon>
						
						<v-card-subtitle>报价精准<br />透明规范</v-card-subtitle>
					</v-card>
				</v-col>
				
				<v-col
				>
					<v-card
						elevation="0"
						style="display: flex;justify-content: center;"
					>
						<v-icon
							size="40"
							color="blue"
						>
							mdi-update
						</v-icon>
						<v-card-subtitle>每日更新<br />价格优惠</v-card-subtitle>
					</v-card>
				</v-col>
			</v-row>
			
			<v-row
				no-gutters
				class="mt-4"
			>
				<v-col
					class="mr-4"
				>
					<v-card
						elevation="0"
						style="display: flex;justify-content: center;"
					>
						<v-icon
							size="40"
							color="amber"
						>
							mdi-truck-fast
						</v-icon>
						
						<v-card-subtitle>付款物流<br />时效保证</v-card-subtitle>
					</v-card>
				</v-col>
				
				<v-col
				>
					<v-card
						elevation="0"
						style="display: flex;justify-content: center;"
					>
						<v-icon
							size="40"
							color="green"
						>
							mdi-face-agent
						</v-icon>
						<v-card-subtitle>服务高效<br />响应快速</v-card-subtitle>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
				
		<v-card
			tile
			elevation="0"
			class="text-center mt-8 bc"
		>
			<v-card-subtitle
				class="text-body-2 pa-2"
			>{{storename}}</v-card-subtitle>
			
			<v-avatar
				size="60"
				rounded
			>
				<v-img :src="avatar"></v-img>
			</v-avatar>
			
			<v-card-subtitle
				class="text-body-2 pa-2"
			>{{contacts}}</v-card-subtitle>
		</v-card>

		<v-card
			elevation="0"
			class="d-flex justify-space-between mb-6 bc"
		>
			<v-spacer></v-spacer>
			<div class="text-center" @click="callPhone">
				<v-icon
					color="orange darken-2"
					size="30"
				>
					mdi-phone-in-talk
				</v-icon>
				
				<v-card-subtitle
					class="text-body-2 pa-2"
				>电话咨询</v-card-subtitle>
			</div>
			<v-spacer></v-spacer>
			<v-divider
				vertical
			></v-divider>
			<v-spacer></v-spacer>
			<div class="text-center" @click="doCopy">
				<v-icon
					color="orange darken-2"
					size="30"
				>
					mdi-wechat
				</v-icon>
				
				<v-card-subtitle
					class="text-body-2 pa-2"
				>复制微信</v-card-subtitle>
			</div>
			<v-spacer></v-spacer>
		</v-card>

		<v-snackbar
			v-model="tips"
			:timeout="timeout"
			centered
			color="rgba(0, 0, 0, 0.7)"
			min-width="0"
		>
			{{tipscontent}}
		</v-snackbar>		
	</v-main>
</template>

<script>
	import router from '../router/index'
	import request from '../utils/request'
	import api from '../utils/api'
  
	export default {
		name: 'Shop',

		data: () => ({
			shophash: "",
			carouselheight: 160,
			ads: [],
			notices: [],
			likes: 0,
			islikes: false,
			storename: "",
			avatar: "",
			contacts: "",
			phone: "",
			wechat: "",
			tips: false,
			tipscontent: "",
			timeout: 0,
			categories: [],
			dealsteps: [
				{
					text: '联系客服',
					disabled: false
				},
				{
					text: '下单付款',
					disabled: false
				},
				{
					text: '服务送达',
					disabled: false
				}
			]
		}),
		mounted: function () {
			const t = this
			t.carouselheight = 160*(document.body.clientWidth/375)
			
			window.onresize = () => {
				return (() => {
					t.carouselheight = 160*(document.body.clientWidth/375)
				})()
			}
			
			this.fetchDetailsData()
		},
		methods:{
			fetchDetailsData: async function () {
				const res = await request.get(api.shopDetails+'/'+this.$route.params.hash, {})
				
				if (res.data.code == 200) {
					window.document.title = '首页 - ' + res.data.data.shopname
					this.shophash = res.data.data.shophash
					this.ads = res.data.data.ads
					this.notices = res.data.data.notices
					this.likes = res.data.data.likes
					this.islikes = res.data.data.islikes
					this.storename = res.data.data.storename
					this.avatar = res.data.data.avatar
					this.contacts = res.data.data.contacts
					this.phone = res.data.data.phone
					this.wechat = res.data.data.wechat
					this.categories = res.data.data.categories
				}else{
					router.push({name:'notfound'})
				}
			},
			callPhone: function(){
				window.location.href = 'tel:' + this.phone
			},
			doCopy: function(){
				let t = this
				this.$copyText(this.wechat).then(function () {
					t.tipscontent = "微信号复制成功"
					t.tips = true
					t.timeout = 1000
				}, function () {
					t.tipscontent = "不能复制微信号"
					t.tips = true
					t.timeout = 1000
				})
			},
			goGoods: async function(hash){
				router.push({name:'goods', params:{ shophash: this.shophash, hash: hash }})
			},
			goSearch: async function(){
				router.push({name:'search', params:{ shophash: this.shophash }})
			},
			like: async function(){
				const res = await request.post(api.shopLikes, {shophash: this.shophash})
				
				if (res.data.code == 200) {
					this.islikes = res.data.data.islikes
					
					if(res.data.data.islikes){
						this.likes = this.likes+1
					}else{
						this.likes = this.likes-1
					}
				}
			},
			share: async function(){
				this.tipscontent = "请使用浏览器分享功能进行分享"
				
				this.timeout = 3000
				this.tips = true
			}
		}
	}
</script>
