<template>
	<v-main
		class="ma-4"
	>
		<v-card
			class="pa-2"
			style="overflow: hidden;justify-content: space-around;display:flex;"
			elevation=0
		>
			<v-text-field
				v-model="keyword"
				prepend-icon="mdi-magnify"
				class="mt-0 pt-0 mr-1"
				hide-details
				@focus="focus"
				@blur="blur"
			></v-text-field>
			
			<v-btn
				ref="searchbtn"
				text
				@click="search"
			>
				搜索
			</v-btn>
		</v-card>
		
		<v-card
			v-if="searching"
			elevation="0"
			class="mt-4 pa-2"
		>
			<v-card-title
				class="pa-0 text-body-1"
			>
				搜索历史
				
				
			</v-card-title>
			<v-btn
				v-if="historys.length > 0"
				icon
				style="position: absolute;right: 0px;top: 4px;"
				@click="historyclear"
			>
				<v-icon>mdi-trash-can-outline</v-icon>
			</v-btn>
			<v-chip
				v-for="(h, hi) in historys"
				:key="'h'+hi"
				close
				class="mt-2 mr-2"
				@click:close="historyclose(h.hash, hi)"
				@click="history(h.keyword)"
			>
				{{h.keyword}}
			</v-chip>
		</v-card>
		
		<v-card
			v-if="!searching && goods.length > 0"
			elevation="0"
			class="mt-4 pa-2"
		>
			<v-list
				class="pa-2"
			>
				<template v-for="(item, index) in goods">
					<v-list-item
						class="pl-0 pr-0"
						:key="'item'+index"
					>
						<v-list-item-content>
							<v-list-item-title v-html="item.name" class="pb-2"></v-list-item-title>
							<template v-for="(it, i) in item.sku">
								<v-list-item
									class="pl-0 pr-0"
									:key="'childitem'+i"
								>
									<v-list-item-avatar
										rounded="sm"
										class="mt-0 mb-0"
									>
										<v-img :src="it.image"></v-img>
									</v-list-item-avatar>
									
									<v-list-item-content>
										<v-list-item-title class="text-caption"><span v-html="it.specs"></span><span class="float-right" v-html="'￥'+it.price/100+'/'+it.unit"></span></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider
						v-if="goods.length-1 != index"
						:key="'divider'+index"
					></v-divider>
				</template>
			</v-list>
		</v-card>
		
		<v-card
			v-if="!searching && goods.length == 0"
			elevation="0"
			align="center"
			class="mt-4 pa-2"
		>
			<v-card-subtitle>暂无产品报价</v-card-subtitle>
		</v-card>
		
	</v-main>
</template>

<script>
	import request from '../utils/request'
	import api from '../utils/api'
	
	export default {
		name: 'Search',
		
		data: () => ({
			searching: true,
			keyword: "",
			goodstitle: "",
			goods: [],
			historys: []
		}),
		mounted: function () {
			this.fetchDetailsData()
		},
		methods:{
			fetchDetailsData: async function () {
				const res = await request.get(api.historyList+'/'+this.$route.params.shophash, {})
				
				if (res.data.code == 200) {
					window.document.title = '搜索 - ' + res.data.data.shopname
					this.historys = res.data.data.list
				}
			},
			focus: async function(){
				this.searching = true
				this.fetchDetailsData()
			},
			blur: async function(){
				this.searching = false
			},
			search: async function(){
				if(this.keyword != ""){
					this.searching = false
					
					const res = await request.get(api.searchList+'/'+this.$route.params.shophash+'/'+encodeURIComponent(this.keyword), {})
					
					if (res.data.code == 200) {
						this.goods = res.data.data.list
					}
				}
			},
			history: async function(k){
				this.keyword = k
				this.$refs.searchbtn.$el.click()
			},
			historyclose: async function(h, k){
				const res = await request.post(api.historyDelete, {historyhash: h})
				
				if (res.data.code == 200) {
					this.historys.splice(k,1)
				}
			},
			historyclear: async function(){
				const res = await request.post(api.historyClear, {})
				
				if (res.data.code == 200) {
					this.historys.splice(0,this.historys.length)
				}
			}
		}
	}
</script>

<style>
</style>
