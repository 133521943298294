<template>
  <v-app class="bc">
    <v-main>
		<router-view></router-view>
    </v-main>

	<v-footer padless class="bc">
		<v-col
			class="text-center text-caption"
		>
			&copy;{{ new Date().getFullYear() }}<br />腾泡科技
		</v-col>
	</v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
	//
  },

  data: () => ({
    //
  }),
};
</script>

<style type="text/css">
	.bc{background-color: #F1F1F1!important;}
</style>
