<template>
	<v-main
		class="ma-4"
	>
		<v-card
			elevation="0"
		>
			<v-card-title>Oops!</v-card-title>
			<v-card-subtitle>Page Not Found!</v-card-subtitle>
			<v-card-text>- 404 -</v-card-text>
		</v-card>
		
		<v-card
			class="mt-8 bc"
			align="center"
			flat
		>
			<v-btn
				text
				color="orange"
				@click="goHome"
			>
				返回首页
			</v-btn>
		</v-card>
	</v-main>
</template>

<script>
	import router from '../router/index'
	
	export default {
		name: 'NotFound',
		
		methods:{
			goHome: async function () {
				router.push({name:'home'})
			}
		}
	}
</script>

<style>
</style>
