import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../view/Home.vue'
import Shop from '../view/Shop.vue'
import Goods from '../view/Goods.vue'
import Search from '../view/Search.vue'
import NotFound from '../view/NotFound.vue'

Vue.use(VueRouter)

const Router = new VueRouter({
	routes:[
		{
			path: '/',
			name: 'home',
			component: Home,
			meta:{
				title:'腾泡报价小店'
			}
		},
		{
			path: '/:hash',
			name: 'shop',
			component: Shop,
			meta:{
				title:'腾泡报价小店'
			}
		},
		{
			path: '/goods/:shophash/:hash',
			name: 'goods',
			component: Goods,
			meta:{
				title:'腾泡报价小店'
			}
		},
		{
			path: '/search/:shophash',
			name: 'search',
			component: Search,
			meta:{
				title:'腾泡报价小店'
			}
		},
		{
			path: '/page/404',
			name: 'notfound',
			component: NotFound,
			meta:{
				title:'404'
			}
		}
	]
})

Router.beforeEach((to, from, next)=>{
	window.document.title = to.meta.title
	next()
})

export default Router
