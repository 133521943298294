<template>
	<v-main
		class="ma-4"
	>
		<v-card
			v-if="goods.length > 0"
			elevation="0"
			class="pa-2"
		>
			<v-list
				class="pa-2"
			>
				<template v-for="(item, index) in goods">
					<v-list-item
						class="pl-0 pr-0"
						:key="'item'+index"
					>
						<v-list-item-content>
							<v-list-item-title v-html="item.name" class="pb-2"></v-list-item-title>
							<template v-for="(it, i) in item.sku">
								<v-list-item
									class="pl-0 pr-0"
									:key="'childitem'+i"
								>
									<v-list-item-avatar
										rounded="sm"
										class="mt-0 mb-0"
									>
										<v-img
											:src="it.image"
											@click="popupimg(it.image)"
										></v-img>
									</v-list-item-avatar>
									
									<v-list-item-content>
										<v-list-item-title class="text-caption"><span v-html="it.specs"></span><span class="float-right" v-html="'￥'+it.price/100+'/'+it.unit"></span></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider
						v-if="goods.length-1 != index"
						:key="'divider'+index"
					></v-divider>
				</template>
			</v-list>
		</v-card>
		
		<v-card
			v-if="goods.length == 0"
			elevation="0"
			align="center"
		>
			<v-card-subtitle>暂无产品报价</v-card-subtitle>
		</v-card>
		
		<v-card
			v-if="goods.length == 0"
			class="mt-8 bc"
			align="center"
			flat
		>
			<v-btn
				text
				color="orange"
				@click="$router.back(-1)"
			>
				返回
			</v-btn>
		</v-card>
		
		<v-dialog
			v-model="popup"
		>
			<v-card>
				<v-card-text>
					<v-img
						:src="popupimage"
					></v-img>
				</v-card-text>
				
				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="popup = false"
					>
						关闭
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<script>
	import request from '../utils/request'
	import api from '../utils/api'
	
	export default {
		name: 'Goods',
		
		data: () => ({
			popup: false,
			popupimage: "",
			goodstitle: "",
			goods: []
		}),
		mounted: function () {
			this.fetchDetailsData()
		},
		methods:{
			fetchDetailsData: async function () {
				const res = await request.get(api.goodsList+'/'+this.$route.params.shophash+'/'+this.$route.params.hash, {})
				
				if (res.data.code == 200) {
					window.document.title = res.data.data.category + ' - ' + res.data.data.shopname
					this.goods = res.data.data.list
				}
			},
			popupimg: async function(img){
				this.popup = true
				this.popupimage = img
			}
		}
	}
</script>

<style>
</style>
