<template>
	<v-main
		class="ma-4"
	>
		<v-card
			elevation="0"
			class="text-center mt-16 pa-14"
		>
			<v-avatar
				size="100"
				rounded="0"
			>
				<v-img src="../assets/logo.png"></v-img>
			</v-avatar>
			
			<v-card-subtitle
				class="text-h6"
			>腾泡报价小店</v-card-subtitle>
			
			<v-card-text>一个轻便的商品报价工具</v-card-text>
			
			<div
				class="mt-16"
			>
				<v-btn
					v-for="(recommend, index) in recommends"
					:key="'recommend'+index"
					rounded
					color="orange"
					class="pl-8 pr-8 white--text mt-4"
					style="width: 80%;"
					@click="goShop(recommend.hash)"
				>
					{{recommend.name}}
				</v-btn>
			</div>
		</v-card>
		
	</v-main>
</template>

<script>
	import request from '../utils/request'
	import api from '../utils/api'
	import router from '../router/index'
	
	export default {
		name: 'Home',
		data: () => ({
			recommends: []
		}),
		mounted: function () {
			this.fetchDetailsData()
		},
		methods:{
			fetchDetailsData: async function () {
				const res = await request.get(api.shopRecommend, {})
				
				if (res.data.code == 200) {
					this.recommends = res.data.data.list
				}
			},
			goShop: async function(hash){
				router.push({name:'shop', params:{ hash: hash }})
			}
		}
	}	
</script>

<style>
</style>
